import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';

const loadTrackers = {
  isOpeningSubmission: false,
  isFormSubmitting: false,
  loadingStyleGuides: false,
  loadingSGCharacters: false,
  collabDetailsLoading: false,

  isLoadingAttachments: false,
  isLoadingSafetyDocs: false,
  isLoadingColabDocs: false,

}
let submissionLoadingTracker = reactive({ ...loadTrackers })

export default () => {

  const updateSubmissionLoadingTracker = (updated) => {
    // console.log(`>>> called updateSubmissionLoadingTracker`)
    // console.log(updated)
    submissionLoadingTracker = Object.assign(submissionLoadingTracker, updated);
    // console.log(submissionLoadingTracker)
};

    return {
        updateSubmissionLoadingTracker,
        submissionLoadingTracker
    };
};
