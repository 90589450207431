<template>
  <div
    v-if="__submissionLoadingTracker.collabDetailsLoading || !collaborationForm.collaborationId"
    class="flex items-center justify-center w-full py-28 h-screen sm:py-28 md:py-36 lg:py-52"
  >
    <base-svg
      class="h-4 w-4 mr-1 text-primary-red inline-block"
      src="icons/circleSpinner.svg"
      tag="span"
    />
    Loading ...
  </div>
  <template v-else>
    <submission-details />
    <collaboration-details />
    <collaborators />
    <collaboration-comments />
    <collaboration-common-uploads />
    <div class="flex">
      <base-button
        v-if="isCollaborationActive"
        variant="btn-primary"
        class="mr-4"
        text="Submit"
        :disabled="isCommentEmpty || isColabDocsLoading"
        @click="handleSubmit()"
      />
      <base-button
        variant="btn-link"
        text="Cancel"
        :disabled="isColabDocsLoading"
        @click="handleCancel()"
      />
      <div class="flex-grow" />

      <base-button
        v-if="isCollaborationActive && isCurrentUserMasterCollaborator"
        variant="btn-primary"
        text="End Collaboration"
        :disabled="isColabDocsLoading"
        @click="handleEndCollaboration()"
      />
    </div>
    <!-- confirm submit modal -->
    <base-modal
      v-model="showConfirmSubmitModal"
      modal-title="Confirm Submit"
    >
      <template #modalBody>
        <div class="px-2 py-3">
          <div class="font-sm text-center mb-2">
            Continue submitting the collaboration details?
          </div>
          <div class="text-center">
            <base-button
              class="mt-2 mb-2 mr-4"
              :is-loading="isCollaborationUpdating"
              variant="btn-primary"
              text="Yes"
              @click="submitForm()"
            />
            <base-button
              class="mt-2 mb-2"
              variant="btn-link"
              text="No"
              @click="cancelSubmit()"
            />
          </div>
        </div>
      </template>
    </base-modal>
  </template>

  <!-- confirm end collaboration modal -->
  <confirm-end-collaboration-modal
    v-model="showConfirmEndCollabModal"
    @accepted="endThisCollaboration()"
    @rejected="setConfirmEndCollabModalVisibility(false)"
  />
</template>

<script>
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import useCollaborationForm from './collaborationForm.js';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import useSubmissionLoadTracker from '@/components/brand-assurance-submission-form/submissionLoadTracker.js';

import useToastNotifications from '@/hooks/toastNotifications.js';
import { SUCCESS, ERROR, WARNING, USER_NOT_MASTER_COLLABORATOR } from '@/constants/alerts.js';

export default {
    name: 'BrandAssuranceCollaborationForm',

    components: {
        SubmissionDetails: defineAsyncComponent(() => import('./SubmissionDetails.vue')),
        CollaborationDetails: defineAsyncComponent(() => import('./CollaborationDetails.vue')),
        Collaborators: defineAsyncComponent(() => import('./Collaborators.vue')),
        CollaborationComments: defineAsyncComponent(() => import('./CollaborationComments.vue')),
        CollaborationCommonUploads: defineAsyncComponent(() => import('./CollaborationCommonUploads.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        ConfirmEndCollaborationModal: defineAsyncComponent(() => import('@/components/ConfirmEndCollaborationModal.vue'))
    },

    emits: [
        'hideCollabModal',
        'collaborationUpdated'
    ],

    setup (props, { emit }) {
        const store = useStore();
        const { collaborationForm, updateCollaborationForm, resetCollaborationForm, isCollaborationActive, isCommentEmpty } = useCollaborationForm();
        const { submissionForm } = useSubmissionForm();
        const { updateSubmissionLoadingTracker, submissionLoadingTracker } = useSubmissionLoadTracker();
        const __submissionLoadingTracker = reactive({ collabDetailsLoading: false });

        const { notificationsStack } = useToastNotifications();
        const userId = computed(() => store.getters['auth/getUserId']);
        const userInfo = computed(() => store.getters['auth/getUserInfo']);

        onMounted(async () => {
            let __collabId = collaborationForm.collaborationId
            if (!__collabId) __collabId = await store.dispatch('baCollaborations/generateNewCollaborationId', {
                params: {
                    submissionType: 'submissionType',
                    table: 'collaboration'
                }
            });

            updateCollaborationForm({ collaborationId: __collabId });
        });

        onUnmounted(() => {
            resetCollaborationForm();
        });

        const handleSubmit = () => {
            if (collaborationForm?.collaborators?.length === 0) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'You should add atleast two collaborators to submit a collaboration form'
                });
            } else {
                setConfirmSubmitModalVisibility(true);
            }
        };
        const handleCancel = () => {
            emit('hideCollabModal');
        };

        // confirm submit modal
        const isCollaborationUpdating = ref(false);

        const submitForm = async () => {
            try {
                if (isCollaborationUpdating.value) {
                    return;
                }
                isCollaborationUpdating.value = true;
                const bodyPayload = getCollaborationPayloadAsExpectedByServer();

                await store.dispatch('baCollaborations/updateCollaboration', {
                    bodyPayload
                });

                setConfirmSubmitModalVisibility(false);
                emit('collaborationUpdated');
                emit('hideCollabModal');

                notificationsStack.value.push({
                    type: SUCCESS,
                    message: 'Collaboration submitted.'
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Cannot submit collaboration details.'
                });
            } finally {
                isCollaborationUpdating.value = false;
            }
        };

        const getCollaborationPayloadAsExpectedByServer = () => {
            return {
                ...collaborationForm,
                assets: collaborationForm.assets?.filter(a => a.stage && a.step),
                status: 1,
                collaborationMasterId: collaborationForm.collaborationMasterId ? collaborationForm.collaborationMasterId : userId.value,
                collaborationId: collaborationForm.collaborationId,
                stageKey: submissionForm.currentStage || collaborationForm.stageKey,
                stepKey: submissionForm.currentStep || collaborationForm.stepKey,
                comment: {
                    ...collaborationForm.comment,
                    creatUserId: userId.value,
                    creatUserName: userInfo.value.name,
                    submissionId: collaborationForm.collaborationId,
                    submissionStageKey: submissionForm.currentStage || collaborationForm.stageKey,
                    submissionStepKey: submissionForm.currentStep || collaborationForm.stepKey,
                    submissionStatusKey: submissionForm.status
                }
            };
        };
        const cancelSubmit = () => {
            setConfirmSubmitModalVisibility(false);
        };
        const showConfirmSubmitModal = ref(false);
        const setConfirmSubmitModalVisibility = (visibility) => {
            showConfirmSubmitModal.value = visibility;
        };

        watch(
            __submissionLoadingTracker,
            () => {
                updateSubmissionLoadingTracker({ ...__submissionLoadingTracker });
            },
            { deep: true, immediate: true }
        );

        const isColabDocsLoading = ref(false);

        watch(
            submissionLoadingTracker,
            () => {
                if (submissionLoadingTracker && submissionLoadingTracker.isLoadingColabDocs) isColabDocsLoading.value = true;
                else isColabDocsLoading.value = false;
            }
        );

        // confirm end collaboration modal logic
        const showConfirmEndCollabModal = ref(false)
        const isCurrentUserMasterCollaborator = computed(() => collaborationForm.collaborationMasterId === userId.value);
        const handleEndCollaboration = () => {
            if (isCurrentUserMasterCollaborator.value) setConfirmEndCollabModalVisibility(true);
            else {
                notificationsStack.value.push({
                    type: WARNING,
                    message: USER_NOT_MASTER_COLLABORATOR
                });
            }
        };

        const endThisCollaboration = async () => {
            try {
                await store.dispatch('baCollaborations/closeCollaboration', {
                    bodyPayload: { status: 2 },
                    collabId: collaborationForm.collaborationId
                });
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: 'Collaboration closed successfully.'
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Error while closing the collaboration.'
                });
            } finally {
                setConfirmEndCollabModalVisibility(false);
                emit('collaborationUpdated');
                emit('hideCollabModal');
            }
        };

        const setConfirmEndCollabModalVisibility = (visibility) => {
            showConfirmEndCollabModal.value = visibility;
        };

        return {
            collaborationForm,
            isCollaborationActive,
            isCommentEmpty,

            handleSubmit,
            handleCancel,
            // confirm submit modal
            submitForm,
            isCollaborationUpdating,
            cancelSubmit,
            showConfirmSubmitModal,
            setConfirmSubmitModalVisibility,
            __submissionLoadingTracker,
            isColabDocsLoading,

            showConfirmEndCollabModal,
            isCurrentUserMasterCollaborator,
            handleEndCollaboration,
            endThisCollaboration,
            setConfirmEndCollabModalVisibility
        };
    }
};
</script>
